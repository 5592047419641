import { Component, OnInit, PipeTransform} from '@angular/core';
import {AngularFirestore} from "@angular/fire/compat/firestore";
import {ColumnMode, SelectionType} from '@swimlane/ngx-datatable';
import {BlockUI, NgBlockUI} from "ng-block-ui";
import {Router} from "@angular/router";

interface Employee {
  id: number;
  firstname: string;
  lastname: string;
  username: string;
}


@Component({
  selector: 'app-all-customer',
  templateUrl: './all-customer.component.html',
  styleUrls: ['./all-customer.component.css'],

})
export class AllCustomerComponent implements OnInit {
  @BlockUI('projectInfo') blockUIProjectInfo: NgBlockUI;
  public breadcrumb: any;
  options = {
    close: true,
    expand: true,
    minimize: true,
    reload: true
  };


  a:any;
  temp:any;
  entries: number = 10;
  selected: any[] = [];

  SelectionType = SelectionType;
  ColumnMode = ColumnMode;
  rows:any;
  private activeRow: any;
  constructor(private db: AngularFirestore,
              public router: Router) {

  }

  ngOnInit() {
    this.a = this.db.collection('customer').valueChanges({idField: 'cid'});
    this.a.subscribe((data: any[]) => {
      this.rows = data;
      this.temp = this.rows.map((prop, key) => {

        return {
          ...prop,
          id: key
        };
      });
      // this.temp =data;
      console.table(this.temp);

    });
  }

  filterTable(event) {
    let val = event.target.value.toLowerCase();
    this.temp = this.rows.filter(function(d: { [x: string]: string; }) {
      for (var key in d) {
        if (d[key].toLowerCase().indexOf(val) !== -1) {
          return true;
        }
      }
      return false;
    });
  }
  reloadProjectInfo($event: any) {
    this.blockUIProjectInfo.start('Loading..');

    setTimeout(() => {
      this.blockUIProjectInfo.stop();
    }, 2500);
  }
  onActivate(event: { row: any; }) {
    this.activeRow = event.row;
  }
  onSelect({ selected }) {
    this.router.navigate(['viewCustomer/'+ this.selected[0].cid])
    console.log('Select Event', selected, this.selected);
  }
}



