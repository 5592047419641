<div class="app-content content">
    <div class="content-wrapper">
        <div class="content-header row mb-1">
            <app-breadcrumb class="col-12" ></app-breadcrumb>
        </div>
        <div class="content-body">
            <!-- Basic form layout section start -->
            <section>
                <div class="row">

<div class="col-md-12" *blockUI="'userProfile'; message: 'Loading'">
<m-card [options]="options" (reloadFunction)="reloadUserProfile($event)">
    <ng-container mCardHeaderTitle>
        User Profile
    </ng-container>
    <ng-container mCardBody>
        <div class="card-text">
            <p class="card-text">With supporting text below as a natural lead-in to additional content.</p>
        </div>
        <form class="form" [formGroup]="userProfile">
            <div class="form-body">
                <h4 class="form-section"><i class="la la-eye"></i> About User</h4>
                <div class="row">
                    <div class="col-md-6">
                        <div class="form-group">
                            <input type="text" id="userinput1" class="form-control" placeholder="First Name"
                                   formControlName="name">
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="form-group">
                            <input type="text" id="userinput2" class="form-control" placeholder="Last Name"
                                   formControlName="phone">
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-6">
                        <div class="form-group">
                            <input type="text" id="userinput3" class="form-control" placeholder="Username"
                                   formControlName="ic">
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="form-group">
                            <input type="text" id="userinput4" class="form-control" placeholder="Nick Name"
                                   formControlName="email">
                        </div>
                    </div>
                </div>
            </div>

            <div class="form-actions right">
                <button type="button" class="btn btn-outline-warning mr-1">
                    <i class="feather ft-x"></i> Cancel
                </button>
                <button type="submit" class="btn btn-outline-primary">
                    <i class="feather ft-check"></i> Save
                </button>
            </div>
        </form>
    </ng-container>
</m-card>
</div>
                </div>
            </section>
        </div>
    </div>
</div>
