import { Component, OnInit } from '@angular/core';
import {AngularFirestore} from "@angular/fire/compat/firestore";
import {ActivatedRoute, Router} from "@angular/router";
import {AngularFireAuth} from "@angular/fire/compat/auth";
import {Observable} from "rxjs";
import {BlockUI, NgBlockUI} from "ng-block-ui";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";

@Component({
  selector: 'app-customer-profile',
  templateUrl: './customer-profile.component.html',
  styleUrls: ['./customer-profile.component.css']
})

export class CustomerProfileComponent implements OnInit {
  @BlockUI('userProfile') blockUIUserProfile: NgBlockUI;

  public breadcrumb: any;
  options = {
    close: true,
    expand: true,
    minimize: true,
    reload: true
  };
  private customerRef: Observable<unknown | undefined>;
  public customerProfile: any[];
  userProfile: FormGroup;

  constructor(public db: AngularFirestore,
              public router: Router,
              public auth: AngularFireAuth,
              public route: ActivatedRoute,
              private formBuilder: FormBuilder) { }

  ngOnInit(): void {

    this.route.params.subscribe(params => {
      if (params['customerID']) {

        this.customerRef = this.db.collection('customer').doc(params['customerID']).valueChanges({idField: 'cid'});
        this.customerRef.subscribe((data: any[]) => {
        this.customerProfile = data;
this.userProfile.patchValue(data);
        });
      }
    })
    this.userProfile = this.formBuilder.group({
      name: ['', Validators.required],
      ic: ['w', Validators.required],
      email: ['1', Validators.required],
      phone: ['2', Validators.required],
      address: ['3', Validators.required],
      petName: ['', Validators.required],
      petMicrochip: ['', Validators.required],
      petPassport: ['', Validators.required],
      petSpecies: ['', Validators.required],
      petBreed: ['', Validators.required],
      petAge: ['', Validators.required],
      petDOB: ['', Validators.required],
      petSex: ['', Validators.required],
      createdAt: ['dd', Validators.required]

    });

  }
  reloadUserProfile() {
    this.blockUIUserProfile.start('Loading..');

    setTimeout(() => {
      this.blockUIUserProfile.stop();
    }, 2500);
  }
}
