<div class="app-content content">
    <div class="content-wrapper">
        <div class="content-header row mb-1">
            <app-breadcrumb class="col-12" ></app-breadcrumb>
        </div>
        <div class="content-body">
            <!-- Basic form layout section start -->
            <section>
                <div class="row">
                    <div class="col-sm-12 col-md-6">
                        <div id="datatable_filter" class="dataTables_filter">
                            <label>
                                <input
                                        type="search"
                                        class="form-control form-control-sm"
                                        placeholder="Search records"
                                        aria-controls="datatable"
                                        (keyup)="filterTable($event)"
                                />
                            </label>
                        </div>
                    </div>
                </div>
                <div class="row">
                <div class="col-12">
                        <ngx-datatable
                                class="material selection-cell"
                                [columnMode]="ColumnMode.force"
                                [scrollbarH]="true"
                                [headerHeight]="50"
                                [footerHeight]="50"
                                [rowHeight]="'auto'"
                                [limit]="entries != -1 ? entries : undefined"
                                [rows]="temp"
                                (activate)="onActivate($event)"
                                (select)='onSelect($event)'
                                [selected]="selected"
                                [selectionType]="SelectionType.single"
                        >
                            <ngx-datatable-column name="name" prop="name"></ngx-datatable-column>
                            <ngx-datatable-column prop="ic"></ngx-datatable-column>
                            <ngx-datatable-column name="email" prop="email"></ngx-datatable-column>
                            <ngx-datatable-column  prop="phone"></ngx-datatable-column>
                            <ngx-datatable-column name="address" prop="address"></ngx-datatable-column>

                            <ngx-datatable-column name="petName" prop="petName"></ngx-datatable-column>
                            <ngx-datatable-column prop="petSpecies"></ngx-datatable-column>
                            <ngx-datatable-column prop="petAge"></ngx-datatable-column>
                            <ngx-datatable-column prop="petBreed"></ngx-datatable-column>
                            <ngx-datatable-column prop="petDOB"></ngx-datatable-column>

                            <ngx-datatable-column name="petSex" prop="petSex"></ngx-datatable-column>
                        </ngx-datatable>

                    </div>
                </div>
            </section>
        </div>
    </div>
</div>