<div class="app-content content">
    <div class="content-wrapper">
        <div class="content-header row mb-1">
            <app-breadcrumb class="col-12" [breadcrumb]="breadcrumb"></app-breadcrumb>
        </div>
        <div class="content-body">
            <!-- Basic form layout section start -->
            <section id="horizontal-form-layouts">
                <div class="row">
                    <div class="col-md-12" *blockUI="'projectInfo'; message: 'Loading'">
                        <m-card [options]="options" (reloadFunction)="reloadProjectInfo($event)">
                            <ng-container mCardHeaderTitle>
                                Add Pet's Owner
                            </ng-container>
                            <ng-container mCardBody>
                                <div class="card-text">

                               </div>
                                <form class="form form-horizontal" [formGroup]="customer" (ngSubmit)="addCustomer()">
                                    <div class="form-body">
                                        <h4 class="form-section"><i class="feather ft-user"></i>Pet's Owner Details</h4>
                                        <div class="form-group row mx-auto">
                                            <label class="col-md-3 label-control" for="projectinput1">Name</label>
                                            <div class="col-md-9">
                                                <input type="text" id="projectinput1" class="form-control" formControlName="name"
                                                       placeholder="Name" (keyup)="filterTable($event)">
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-group row auto" *ngIf="showCustomerList">
                                        <div class="col-12">
                                    <ngx-datatable
                                            class="material selection-cell"
                                            [columnMode]="ColumnMode.force"
                                            [scrollbarH]="true"
                                            [headerHeight]="50"
                                            [footerHeight]="50"
                                            [rowHeight]="'auto'"
                                            [rows]="temp"
                                            (select)='onSelect($event)'
                                            [selected]="selected"
                                            [selectionType]="SelectionType.single"
                                            [messages]="{emptyMessage: 'No Profile Created Before\n Please proceed to register'}"
                                    >
                                        <ngx-datatable-column name="name" prop="name"></ngx-datatable-column>
                                        <ngx-datatable-column prop="ic"></ngx-datatable-column>
                                        <ngx-datatable-column name="email" prop="email"></ngx-datatable-column>
                                        <ngx-datatable-column  prop="phone"></ngx-datatable-column>
                                        <ngx-datatable-column name="address" prop="address"></ngx-datatable-column>
                                    </ngx-datatable>
                                    </div>
                    </div>
                                    <div class="form-group row mx-auto">
                                        <label class="col-md-3 label-control" for="projectinput2">I/C No</label>
                                        <div class="col-md-9">
                                            <input type="text" id="projectinput2" class="form-control" formControlName="ic"
                                                   placeholder="IC No">
                                        </div>
                                    </div>

                                    <div class="form-group row mx-auto last">
                                        <label class="col-md-3 label-control" for="projectinput4">Contact Number</label>
                                        <div class="col-md-9">
                                            <input type="text" id="projectinput4" class="form-control" formControlName="phone"
                                                   placeholder="Phone">
                                        </div>
                                    </div>
                                    <div class="form-group row mx-auto last">
                                    <label class="col-md-3 label-control" for="projectinput5">Address</label>
                                    <div class="col-md-9">
                                        <input type="text" id="projectinput5" class="form-control" formControlName="address"
                                               placeholder="Address">
                                    </div>
                                </div>
                                    <div class="form-group row mx-auto">
                                        <label class="col-md-3 label-control" for="projectinput3">E-mail</label>
                                        <div class="col-md-9">
                                            <input type="text" id="projectinput3" class="form-control" formControlName="email"
                                                   placeholder="E-mail">
                                        </div>
                                    </div>
                                    <h4 class="form-section"><i class="feather ft-clipboard"></i> Pet's Details</h4>
                                    <div class="form-group row mx-auto">
                                        <label class="col-md-3 label-control" for="petName">Name</label>
                                        <div class="col-md-9">
                                            <input type="text" id="petName" class="form-control" formControlName="petName"
                                                   placeholder="Pet Name">
                                        </div>
                                    </div>
                                    <div class="form-group row mx-auto">
                                        <label class="col-md-3 label-control" for="petPassport">Passport Number</label>
                                        <div class="col-md-9">
                                            <input type="text" id="petPassport" class="form-control" formControlName="petPassport"
                                                   placeholder="Pet Name">
                                        </div>
                                    </div>
                                    <div class="form-group row mx-auto">
                                    <label class="col-md-3 label-control" for="petMicrochip">Microchip Number</label>
                                    <div class="col-md-9">
                                        <input type="text" id="petMicrochip" class="form-control" formControlName="petMicrochip"
                                               placeholder="Pet Name">
                                    </div>
                                </div>
                                         <div class="form-group row mx-auto">
                                        <label class="col-md-3 label-control" for="petName">Species</label>
                                        <div class="col-md-9">
                                            <input type="text" id="petSpecies" class="form-control" formControlName="petSpecies"
                                                   placeholder="Pet Species">
                                        </div>
                                    </div>
                                    <div class="form-group row mx-auto">
                                        <label class="col-md-3 label-control" for="petName">Age</label>
                                        <div class="col-md-9">
                                            <input type="text" id="petAge" class="form-control" formControlName="petAge"
                                                   placeholder="Pet Species">
                                        </div>
                                    </div>
                                    <div class="form-group row mx-auto">
                                        <label class="col-md-3 label-control" for="petName">Breed</label>
                                        <div class="col-md-9">
                                            <input type="text" id="petBreed" class="form-control" formControlName="petBreed"
                                                   placeholder="Pet Breed">
                                        </div>
                                    </div>
                                         <div class="form-group row mx-auto">
                                        <label class="col-md-3 label-control" for="petName">D.O.B</label>
                                        <div class="col-md-9">
                                            <input type="text" id="petDOB" class="form-control" formControlName="petDOB"
                                                   placeholder="DOB">
                                        </div>
                                    </div>
                                         <div class="form-group row mx-auto">
                                        <label class="col-md-3 label-control" for="petName">Sex</label>
                                        <div class="col-md-9">
                                            <input type="text" id="petSex" class="form-control" formControlName="petSex"
                                                   placeholder="Pet Sex">
                                        </div>
                                    </div>
                                    <div class="form-actions">
                                        <button type="button" class="btn btn-warning mr-1">
                                            <i class="feather ft-x"></i> Cancel
                                        </button>
                                        <button type="submit" class="btn btn-primary" *ngIf="customer.valid">
                                            <i class="la la-check"></i> Save
                                        </button>
                                    </div>
                                </form>
                            </ng-container>
                        </m-card>
                    </div>
                </div>
            </section>
        </div>
    </div>
</div>
