import { Component, OnInit } from '@angular/core';
import { BlockUI, NgBlockUI } from "ng-block-ui";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { AngularFirestore } from "@angular/fire/compat/firestore";
import { AlertService } from '../_services/alert.service';
import {ColumnMode, SelectionType } from '@swimlane/ngx-datatable';
import {Router} from "@angular/router";
export interface Customer {
  name: string;
}
@Component({
  selector: 'app-add-customer',
  templateUrl: './add-customer.component.html',
  styleUrls: ['./add-customer.component.css']
})
export class AddCustomerComponent implements OnInit {
  @BlockUI('projectInfo') blockUIProjectInfo: NgBlockUI;
  @BlockUI('userProfile') blockUIUserProfile: NgBlockUI;

  public breadcrumb: any;
  entries: number = 10;
  selected: any[] = [];

  SelectionType = SelectionType;
  ColumnMode = ColumnMode;
  temp:any;

  options = {
    close: true,
    expand: true,
    minimize: true,
    reload: true
  };
customer : FormGroup;
  showCustomerList = false;
  public rows: any;
  private a: any;
  constructor(
      private db: AngularFirestore,
      public router: Router,
      private formBuilder: FormBuilder,
      private afs: AngularFirestore,
      private alertService: AlertService,
  ) { }

  ngOnInit(): void {
    this.breadcrumb = {
      'mainlabel': 'Add Customer',
      'links': [
        {
          'name': 'Home',
          'isLink': true,
          'link': '/dashboard/sales'
        },
        {
          'name': 'Add Customer',
          'isLink': true,
          'link': '#'
        }
      ]
    };

    this.a = this.db.collection('customer').valueChanges({idField: 'cid'});
    this.a.subscribe((data: any[]) => {
      this.rows = data;
      // this.temp = this.rows.map((prop, key) => {
      //   return {
      //     ...prop,
      //     id: key
      //   };
      // });

    })

    this.customer = this.formBuilder.group({
      email: ['', Validators.required],
      name: ['', Validators.required],
      ic: ['', Validators.required],
      phone: ['', Validators.required],
      address: ['', Validators.required],
      petName: ['', Validators.required],
      petMicrochip: ['', Validators.required],
      petPassport: ['', Validators.required],
      petSpecies: ['', Validators.required],
      petBreed: ['', Validators.required],
      petAge: ['', Validators.required],
      petDOB: ['', Validators.required],
      petSex: ['', Validators.required],
      createdAt: ['dd', Validators.required]
    });
    // convenience getter for easy access to form fields
  }

  // convenience getter for easy access to form fields
  get f() { return this.customer.controls; }
  reloadProjectInfo($event: any) {
    this.blockUIProjectInfo.start('Loading..');

    setTimeout(() => {
      this.blockUIProjectInfo.stop();
    }, 2500);
  }
    addCustomer() {
        this.afs.collection('customer').add(
            this.customer.value
        ).then(d => {
          this.alertService.success("Successfully Added");
        })
    }
  filterTable(event) {
    this.showCustomerList = true;
    let val = event.target.value.toLowerCase();

    this.temp = this.rows.filter(function(d: { [x: string]: string; }) {
      for (var key in d) {
        if (d[key].toLowerCase().indexOf(val) !== -1) {
          return true;

        }
      }
      return false;
    });
    if (val ===''){
      this.showCustomerList = false;
    }
  }
  onSelect({ selected }) {
    this.router.navigate(['viewCustomer/'+ this.selected[0].cid])
    console.log('Select Event', selected, this.selected);
  }
}
